body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'threexfive';
    src: local('threexfive'), url(./fonts/threexfive.ttf);
}

@font-face {
    font-family: 'billion';
    src: local('billion'), url(./fonts/BillionStars.ttf);
}

@font-face {
    font-family: 'collegiate';
    src: local('collegiate'), url(./fonts/CollegiateFLF.ttf);
}

@font-face {
    font-family: 'orangeJuice';
    src: local('orangeJuice'), url(./fonts/orangeJuice.ttf);
}

@font-face {
    font-family: 'planetbe';
    src: local('planetbe'), url(./fonts/planetbe.ttf);
}

@font-face {
    font-family: 'prisma';
    src: local('prisma'), url(./fonts/Prisma.ttf);
}

@font-face {
    font-family: 'quirkyRobot';
    src: local('quirkyRobot'), url(./fonts/QuirkyRobot.ttf);
}

@font-face {
    font-family: 'york';
    src: local('york'), url(./fonts/yorkwhiteletter.ttf);
}
